<template>
  <v-file-input
    v-model="files"
    :hide-input="hideinput"
    show-size
    counter
    label="File input"
    prepend-icon="mdi-camera"
    :rules="rules"
    accept="image/png, image/jpeg, image/bmp"
    @change="upload"
  ></v-file-input>
</template>
<script>
import { Create } from '@core/api/File'
import { ref } from '@vue/composition-api'

export default {
  props: {
    ObjectId: {
      type: Number,
      default: () => null,
    },
    Type: {
      type: String,
      default: () => 'ProfilePicture',
    },
    TableName: {
      type: String,
      default: () => null,
    },
    hideinput: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const files = ref([])
    const getBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    const rules = [value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!']
    const upload = () => {
      //console.log(files.value)

      getBase64(files.value).then(data => {
        // console.log(data)
        const file = {
          Id: undefined,
          FileType: 'image',
          File: data.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
          Status: 0,
          Type: props.Type,
          TableName: props.TableName,
          FKTable: props.ObjectId,
        }
        Create(file).then(response => {
          if (response) {
            emit('refetch-data')
          }
        })
      })
    }

    return {
      rules,
      files,
      upload,
    }
  },
}
</script>
