import request from '@core/utils/RequestApi'
import qs from 'qs'

export function GetInfo(data) {
  return request({
    url: '/Restaurant/GetInfo',
    method: 'get',
    params: data,
  })
}

export function Create(data) {
  return request({
    url: '/Restaurant/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Edit(data) {
  return request({
    url: '/Restaurant/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function GetRestaurants() {
  return request({
    url: '/Restaurant/GetRestaurants',
    method: 'get',
  })
}
export function GetByRestaurantNumber(data) {
  return request({
    url: '/Restaurant/GetByRestaurantNumber',
    method: 'get',
    params: data,
  })
}
export function GetIdByPhone(data) {
  return request({
    url: '/Restaurant/GetIdByPhone',
    method: 'get',
    params: data,
  })
}


